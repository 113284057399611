import React, { useState } from 'react';
import { Button, Typography, Box, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Movie as RecommendationsIcon } from '@mui/icons-material';
import { decode } from 'html-entities';
import { getRecommendations } from '../api/youtubeApi';

const YouTubeRecommendations = ({ queryTerm }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchRecommendations = async () => {
    setLoading(true);
    try {
      const responseData = await getRecommendations(queryTerm);
      setRecommendations(responseData);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    } finally {
      setLoading(false);
    }
  };

  const decodedString = (text) => {
    try {
      return decode(text);
    } catch (error) {
      console.error('Error decoding string:', error);
      return null;
    }
  };

  const handleOpen = () => {
    fetchRecommendations();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<RecommendationsIcon />}
        onClick={handleOpen}
        style={{ marginBottom: '10px' }}
      >
        Recommendations
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>YouTube Video Recommendations</DialogTitle>
        <DialogContent>
          {loading && <Typography variant="body1">Loading recommendations...</Typography>}
          <List>
            {recommendations.map((recommendation) => (
              <ListItem
              key={recommendation.id.videoId}
              component="a" // Changed this line
              href={`https://www.youtube.com/watch?v=${recommendation.id.videoId}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  component="img"
                  src={recommendation.snippet.thumbnails.high.url}
                  alt={recommendation.snippet.title}
                  sx={{
                    width: 150, 
                    height: 100, 
                    borderRadius: 2, 
                    objectFit: 'cover', 
                    marginRight: 2
                  }}
                />
                <ListItemText
                  primary={
                    <Typography variant="body1" component="div">
                      <strong>{recommendation.snippet.title}</strong>
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" component="div">
                      {decodedString(recommendation.snippet.description)}
                    </Typography>
                  }
                  sx={{ flex: 1 }} // Make sure ListItemText takes up the remaining space
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default YouTubeRecommendations;
