import React, { useState } from 'react';
import { Button, Modal, Box, Typography, InputLabel, FormControl, Select, MenuItem, LinearProgress } from '@mui/material';
import axiosInstance from '../../utils/axiosInstance';
import SimpleAlert from '../SimpleAlert'; // Make sure to import the SimpleAlert component

const TranscodeModal = ({ open, onClose, videoList = [], lastTaskId, setLastTaskId}) => {
  const [selectedVideo, setSelectedVideo] = useState('');
  const [alert, setAlert] = useState({ show: false, severity: '', title: '', message: '' });

  const [resolution, setResolution] = useState('1280:720');
  const [bitrate, setBitrate] = useState('2500k');
  const [audioBitrate, setAudioBitrate] = useState('128k');
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const resolutions = [
    { label: "1080p", value: "1920:1080" },
    { label: "720p", value: "1280:720" },
    { label: "480p", value: "854:480" },
    { label: "360p", value: "640:360" },
  ];

  const bitrates = [
    { label: "High (5000 kbps)", value: "5000k" },
    { label: "Medium (2500 kbps)", value: "2500k" },
    { label: "Low (1000 kbps)", value: "1000k" },
  ];

  const audioBitrates = [
    { label: "High (256 kbps)", value: "256k" },
    { label: "Medium (128 kbps)", value: "128k" },
    { label: "Low (64 kbps)", value: "64k" },
  ];

  const handleChange = (event) => {
    setSelectedVideo(event.target.value);
  };

  const handleTranscodeClick = async () => {
    setIsLoading(true); // Set loading to true when the task starts
    try {
      // Start the transcoding task
      const response = await axiosInstance.post('/restApi/api/transcoding_tasks/startTask', {
        video_id: selectedVideo,
        resolution,
        bitrate,
        audioBitrate,
      });
      console.log("🚀 ~ handleTranscodeClick ~ response:", response)

      const responseData = response.data;
      const {
        task_id
      } = responseData;

      setLastTaskId(task_id);
    } catch (error) {
      console.error('Error starting transcoding:', error);

      setAlert({
        show: true,
        severity: 'error',
        title: 'Transcoding Error',
        message: 'Failed to start transcoding.',
      });
    } finally {
      setIsLoading(false); // Reset loading state after task completion
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}
      >
        {alert.show && (
          <SimpleAlert
            severity={alert.severity}
            title={alert.title}
            message={alert.message}
            onClose={() => setAlert({ ...alert, show: false })}
          />
        )}

        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 2 }}>
          {lastTaskId !== null ? `Transcoding in Progress | Task ID: ${lastTaskId}` : 'Select a Video to Transcode'}
        </Typography>

          <>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="video-select-label">Video</InputLabel>
              <Select
                labelId="video-select-label"
                id="video-select"
                value={selectedVideo}
                label="video"
                onChange={handleChange}
              >
                {videoList.map((video) => (
                  <MenuItem key={video.video_id} value={video.video_id}>
                    ID: {video.video_id} | {video.original_filename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="resolution-select-label">Resolution</InputLabel>
              <Select
                labelId="resolution-select-label"
                id="resolution-select"
                value={resolution}
                label="Resolution"
                onChange={(e) => setResolution(e.target.value)}
              >
                {resolutions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="bitrate-select-label">Video Bitrate</InputLabel>
              <Select
                labelId="bitrate-select-label"
                id="bitrate-select"
                value={bitrate}
                label="Video Bitrate"
                onChange={(e) => setBitrate(e.target.value)}
              >
                {bitrates.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="audio-bitrate-select-label">Audio Bitrate</InputLabel>
              <Select
                labelId="audio-bitrate-select-label"
                id="audio-bitrate-select"
                value={audioBitrate}
                label="Audio Bitrate"
                onChange={(e) => setAudioBitrate(e.target.value)}
              >
                {audioBitrates.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleTranscodeClick}
              disabled={!selectedVideo || isLoading}
              sx={{ marginTop: 2 }}
            >
              Start Transcoding
            </Button>
          </>
        
      </Box>
    </Modal>
  );
};

export default TranscodeModal;
