import axiosInstance from '../utils/axiosInstance'; // Adjust the path as necessary

export const getChannelStats = async (channelId) => {
    try {
        const response = await axiosInstance.get(`/youtubeApi/api/youtube/channel/${channelId}/stats`);
        return response.data || {}; 
    } catch (error) {
        console.error('Error fetching channel stats:', error);
        return {}; 
    }
};

export const getPlaylistItems = async (playlistId) => {
    try {
        const response = await axiosInstance.get(`/youtubeApi/api/youtube/playlist/${playlistId}/items`);
        return response.data || []; 
    } catch (error) {
        console.error('Error fetching playlist items:', error);
        return []; 
    }
};

export const getRecommendations = async (queryTerm) => {
    try {
        const response = await axiosInstance.get('/youtubeApi/api/youtube/recommendations', {
            params: { q: queryTerm },
        });
        return response.data || []; 
    } catch (error) {
        console.error('Error fetching recommended videos:', error);
        return []; 
    }
};

export const getComments = async (videoId) => {
    try {
        const response = await axiosInstance.get(`/youtubeApi/api/youtube/video/${videoId}/comments`);
        return response.data || [];
    } catch (error) {
        console.error('Error fetching comments:', error);
        return []; 
    }
};
