import React, { useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, signIn, confirmSignIn, signUp } from "aws-amplify/auth";
import { TextField, Button, Typography, Paper, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../context/GlobalContext';

function LoginPage() {
    const [error, setError] = useState(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState(""); // For Sign Up
    const [authCode, setAuthCode] = useState("");  // Input for OTP or sign-in confirmation code
    const [requiresAuthCode, setRequiresAuthCode] = useState(false); // Whether OTP or confirmation code is required
    const [isSigningUp, setIsSigningUp] = useState(false); // Switch between login and sign-up
    const navigate = useNavigate();
    const { checkLogin } = useGlobalContext();

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload }) => {
            switch (payload.event) {
                case "signInWithRedirect":
                    console.log("Sign in with redirect triggered.");
                    navigate('/');
                    break;
                case "signInWithRedirect_failure":
                    setError("An error occurred during the OAuth flow.");
                    break;
                case "customOAuthState":
                    console.log("Custom OAuth state:", payload.data);
                    break;
                default:
                    break;
            }
        });

        return unsubscribe;
    }, []);

    // Handle Login Function
    const signInFunction = async (event) => {
        event.preventDefault();

        try {
            const response = await signIn({ username, password });
            console.log("SignIn Response:", response);

            if (response.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
                setRequiresAuthCode(true);
            } else if (response.nextStep?.signInStep === "DONE") {
                setRequiresAuthCode(false);
                await checkLogin();
                navigate('/');
            }
        } catch (error) {
            console.error("Error signing in:", error);
            setError("Failed to sign in.");
        }
    };

    // Handle Sign-Up Function
    const signUpFunction = async (event) => {
        event.preventDefault();

        try {
            const response = await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        email: email,
                    },
                },
            });
            console.log("SignUp Response:", response);
            setIsSigningUp(false);  // After successful sign-up, return to login
        } catch (error) {
            console.error("Error signing up:", error);
            setError("Failed to sign up.");
        }
    };

    // Confirm Sign In (for OTP during login)
    const handleConfirmSignIn = async (event) => {
        event.preventDefault();

        try {
            const confirmSignInResponse = await confirmSignIn({ challengeResponse: authCode });
            console.log("Confirm SignIn Response:", confirmSignInResponse);

            if (confirmSignInResponse.nextStep?.signInStep === "DONE") {
                setRequiresAuthCode(false);
                await checkLogin();
                navigate('/');
            }
        } catch (error) {
            console.error("Error confirming sign in:", error);
            setError("Failed to confirm sign in.");
        }
    };

    return (
        <>
            <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: 'auto', mt: 8 }}>
                <Typography variant="h4" gutterBottom>{isSigningUp ? "Sign Up" : "Login"}</Typography>
                {error && (
                    <Typography variant="body2" color="error" gutterBottom>
                        {error}
                    </Typography>
                )}
                {!requiresAuthCode ? (
                    <>
                        <form onSubmit={isSigningUp ? signUpFunction : signInFunction}>
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Username"
                                    variant="outlined"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)} />
                            </Box>
                            {isSigningUp && (
                                <Box sx={{ mb: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </Box>
                            )}
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                            </Box>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                {isSigningUp ? "Sign Up" : "Login"}
                            </Button>
                        </form>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Button
                                sx={{ textTransform: 'none' }}
                                startIcon={<GoogleIcon />}
                                variant="outlined"
                                onClick={() => signInWithRedirect({ provider: "Google", customState: "shopping-cart" })}>
                                Sign In with Google
                            </Button>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body2">
                                {isSigningUp ? "Already have an account?" : "Don't have an account?"}{" "}
                                <Button variant="text" onClick={() => setIsSigningUp(!isSigningUp)}>
                                    {isSigningUp ? "Login" : "Sign Up"}
                                </Button>
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <form onSubmit={handleConfirmSignIn}>
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Verification Code"
                                variant="outlined"
                                value={authCode}
                                onChange={(e) => setAuthCode(e.target.value)}
                            />
                        </Box>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Confirm Sign In
                        </Button>
                    </form>
                )}
            </Paper>
        </>
    );
}

export default LoginPage;
