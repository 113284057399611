import React, { useEffect, useState, useRef } from 'react';
import { Modal, Box, Typography, CircularProgress } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const TranscodingProgress = ({ taskId, onClose, showProgress}) => {
  const [progress, setProgress] = useState(0);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const eventSourceRef = useRef(null); // Store the EventSource

  useEffect(() => {
    if (!showProgress) {
      // Close the EventSource when the modal is closed
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null; // Clear reference
      }
      return; // Exit early if modal is closed
    }

    connectToEventSource(); // Initial connection attempt

    // Clean up the EventSource connection on component unmount
    return () => {
      if (eventSourceRef.current) {
        console.log("close return");
        eventSourceRef.current.close();
        eventSourceRef.current = null; // Clear reference
      }
      setProgress(0); // Reset progress state
    };
  }, [showProgress]); // Depend on retryCount to manage connection properly

  const connectToEventSource = () => {
    let targetServer = process.env.REACT_APP_BACKEND_URI;

    if (process.env.REACT_APP_LOCAL_TESTING === 'true') {
      targetServer = process.env.REACT_APP_REST_API_URI;
    }

    const newEventSource = new EventSource(`${targetServer}/restApi/api/sse/progress/${taskId}`);
    eventSourceRef.current = newEventSource; // Store the new EventSource reference

    newEventSource.onmessage = (event) => {
      console.log("Received progress update:", event.data);
      const progressData = JSON.parse(event.data);
      const percent = parseFloat(progressData.percent);
      setProgress(percent);
      setIsDisconnected(false);

      // Close the connection if the progress is 100%
      if (percent >= 100) {
        newEventSource.close();
      }
    };

    newEventSource.onerror = (error) => {
      console.error("Error or connection lost:", error);
      setIsDisconnected(true);

      // Close the current connection
      newEventSource.close(); 

      // Retry connection 
      setTimeout(() => {
        connectToEventSource(); 
      }, 1000); 
    };
  };
  
  return (
    <Modal
      open={showProgress}
      onClose={onClose}
      aria-labelledby="progress-modal-title"
      aria-describedby="progress-modal-description"
    >
      <Box sx={style}>
        <Typography id="progress-modal-title" variant="h6" component="h2">
          Transcoding Progress
        </Typography>
        <CircularProgress variant="determinate" value={progress !== null ? progress : 0} />
        <Typography id="progress-modal-description" sx={{ mt: 2 }}>
          {progress !== null ? `${progress.toFixed(2)}%` : 'Waiting for progress...'}
        </Typography>
        {isDisconnected && (
          <Typography sx={{ color: 'red', mt: 2 }}>
            Disconnected. Trying to reconnect...
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default TranscodingProgress;
