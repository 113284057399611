import React, { useEffect, useState } from 'react';
import { getChannelStats, getPlaylistItems } from '../api/youtubeApi';
import { Container, Typography, Card, CardContent, CardMedia, Grid, Link } from '@mui/material';
import YouTubeComments from './YoutubeComments';
import YouTubeRecommendations from './YouTubeRecommendations';
import YoutubeChannelStatsCard from './YoutubeChannelStatsCard';

const YouTubeChannel = () => {
    const [channelStats, setChannelStats] = useState(null);
    const [playlistItems, setPlaylistItems] = useState([]);

    const channelId = 'UCsBjURrPoezykLs9EqgamOA'; // Fireship Channel ID
    const playlistId = 'PL0vfts4VzfNiI1BsIK5u7LpPaIDKMJIDN'; // Example Playlist ID

    useEffect(() => {
        getDataChannelStats();
        getDataPlaylistItems();
    }, []);


    const getDataChannelStats = async () => {
        try {
            const response = await getChannelStats(channelId);
            console.log("🚀 ~ getDataChannelStats ~ response:", response)

            if (!response) {
                throw new Error('Error fetching channel stats)');
            }

            setChannelStats(response);
        } catch (error) {
            console.error('Error fetching channel stats:', error);
            setChannelStats(null)
        }
    }

    const getDataPlaylistItems = async () => {
        try {
            const response = await getPlaylistItems(playlistId);
            if (!response) {
                throw new Error('Error fetching channel stats)');
            }

            setPlaylistItems(response);
        } catch (error) {
            console.error('Error fetching playlist items:', error);
            setPlaylistItems([])
        }
    }
    return (
        <Container maxWidth="lg">
            {/* checking to prevent bugs */}
            {(channelStats !== null && channelStats !== undefined && Object.keys(channelStats).length > 0) ? (
                <YoutubeChannelStatsCard channelStats={channelStats} />
            ) : null}

            <Typography variant="h4" component="h2" gutterBottom>
                Playlist Items
            </Typography>
            <Grid container spacing={4}>

                {playlistItems.length > 0 && playlistItems.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <Card
                            sx={{
                                height: 450, // Fixed height for the card
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="100"
                                image={item.snippet.thumbnails.high.url}
                                alt={item.snippet.title}
                            />
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    <Link
                                        href={`https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`}
                                        underline="none"
                                        color="primary"
                                        target="_blank"
                                    >
                                        {item.snippet.title}
                                    </Link>
                                </Typography>
                                <YouTubeComments videoId={item.snippet.resourceId.videoId} />
                                <YouTubeRecommendations queryTerm={item.snippet.title} />
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default YouTubeChannel;
