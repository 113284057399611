import React, { useState } from 'react';
import { Button, Typography, Avatar, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ChatBubbleOutline as CommentIcon } from '@mui/icons-material';
import { decode } from 'html-entities';
import { getComments } from '../api/youtubeApi';

const YouTubeComments = ({ videoId }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchComments = async () => {
    setLoading(true);
    try {
      const responseData = await getComments(videoId)
      setComments(responseData);
    } catch (error) {
      console.error('Error fetching comments:', error);
    } finally {
      setLoading(false);
    }
  };

  const decodedString = (text) => {
    try {
      return decode(text);
    } catch (error) {
      console.error('Error decoding string:', error);
      return null;
    }
  };

  const handleOpen = () => {
    fetchComments();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CommentIcon />}
        onClick={handleOpen}
        style={{ marginTop: 10, marginBottom: '10px' }}
        aria-haspopup="dialog"  // Added this line to indicate that it opens a dialog
      >
        Comments
      </Button><Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>YouTube Top 5 Comments </DialogTitle>
        <DialogContent>
          {loading && <Typography variant="body1">Loading comments...</Typography>}
          <List>
            {comments.map((comment) => (
              <ListItem key={comment.id} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={comment.snippet.topLevelComment.snippet.authorProfileImageUrl}
                  sx={{ marginRight: 2 }} // Add margin to the right of the avatar
                />
                <ListItemText
                  primary={<Typography variant="body1" component="div">
                    <strong>{comment.snippet.topLevelComment.snippet.authorDisplayName}</strong>
                  </Typography>}
                  secondary={<Typography variant="body2" component="div">
                    {decodedString(comment.snippet.topLevelComment.snippet.textDisplay)}
                  </Typography>}
                  sx={{ flex: 1 }} // Make sure ListItemText takes up the remaining space
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
};

export default YouTubeComments;
