import React, { useState } from "react";
import { Button, Box, Typography, LinearProgress, Alert } from "@mui/material";
import axiosInstance from '../../utils/axiosInstance';
import axios from 'axios'; // Import Axios for S3 upload

const VideoUploader = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("video")) {
      setSelectedVideo(file);
      setAlertMessage(''); // Clear any previous alert messages
    } else {
      setAlertMessage("Please upload a valid video file.");
      setIsError(true);
    }
  };

  const handleUpload = async () => {
    if (!selectedVideo) {
      setAlertMessage("No video selected");
      setIsError(true);
      return;
    }

    const formData = new FormData();
    formData.append("video", selectedVideo);

    try {

      console.log("select video:", selectedVideo);
      // Step 1: Request the pre-signed URL from the backend
      const response = await axiosInstance.post("/videoManagementApi/api/video/generate-upload-url", {
        filename: selectedVideo.name,
        size: selectedVideo.size,
      });
      
      const { signedUploadUrl } = response.data;
      console.log("Pre-signed URL:", signedUploadUrl);

      // Step 2: Upload video file to S3 using the pre-signed URL
      const uploadResponse = await axios.put(signedUploadUrl, selectedVideo, {
        headers: {
          "Content-Type": selectedVideo.type, // Set the content type as the video file type
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      setAlertMessage("File uploaded successfully!");
      setIsError(false);
      console.log("Upload to S3 response:", uploadResponse);
    } catch (error) {
      console.error("Upload failed:", error);
      setAlertMessage("Upload failed!");
      setIsError(true);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      {alertMessage && (
        <Alert severity={isError ? "error" : "success"}>
          {alertMessage}
        </Alert>
      )}
      
      <input
        accept="video/*"
        style={{ display: "none" }}
        id="contained-button-file"
        type="file"
        onChange={handleVideoChange}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span">
          Select Video
        </Button>
      </label>

      {selectedVideo && (
        <Typography variant="body1">{`Selected video: ${selectedVideo.name}`}</Typography>
      )}

      {uploadProgress > 0 && (
        <Box width="100%" mt={2}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" color="textSecondary">
            {uploadProgress}% uploaded
          </Typography>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={!selectedVideo}
      >
        Upload Video
      </Button>
    </Box>
  );
};

export default VideoUploader;
