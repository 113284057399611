import React, { useEffect, useState } from 'react';
import { Typography, Button, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import Layout from '../components/Layout';
import axiosInstance from '../utils/axiosInstance';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import TranscodeModal from '../components/video/TranscodeModal';
import Dashboard from '../components/Dashboard';
import { useGlobalContext } from '../context/GlobalContext';
import TranscodingProgress from '../components/TranscodingProgress';

function MyVideosPage() {
    const paginationPageSize = 5; // Number of rows per page
    const [videoData, setVideoData] = useState([]);
    const [transcodingTasksData, setTranscodingTasksData] = useState([]);
    const [lastTaskId, setLastTaskId] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { userRole } = useGlobalContext();

    // Video preview
    const [open, setOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    // State to manage selected task for progress
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [showProgress, setShowProgress] = useState(false);

    // Function to close the Transcoding Progress
    const handleCloseProgress = () => {
        setShowProgress(false);
        setSelectedTaskId(null); // Reset selectedTaskId to null when closing
    };

    const handleProgressButtonClick = (taskId) => {
        setSelectedTaskId(taskId);
        setShowProgress(true);
    };

    const handleVideoPreview = async (objectKey) => {
        setLoading(true);
        setOpen(true);

        try {
            const response = await axiosInstance.get(`/videoManagementApi/api/video/generate-download-url?objectKey=${objectKey}`);
            console.log("🚀 ~ handlePreview ~ response:", response);
            setPreviewUrl(response.data.downloadURL);
        } catch (error) {
            console.error("Error fetching video URL:", error);
            setPreviewUrl(null);
        } finally {
            setLoading(false);
        }
    };

    const handleVideoClose = () => {
        setOpen(false);
        setPreviewUrl(null);
    };

    const handleOpenModal = () => setIsModalOpen(true);
    
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setLastTaskId(null); // Reset lastTaskId when closing the modal
    }
    const videoColumnDefs = [
        { headerName: 'Video ID', field: 'video_id', width: 100 },
        { headerName: 'Original Name', field: 'original_filename' },
        { headerName: 'New Name', field: 'new_filename' },
        { headerName: 'Mime Type', field: 'mimetype', width: 120 },
        { headerName: 'User ID', field: 'user_id', width: 100 },
        { headerName: 'AWS Object Key', field: 'aws_object_key', width: 100 },
        { headerName: 'Uploaded On', field: 'create_ts', cellRenderer: (params) => new Date(params.value).toLocaleString() },
        { headerName: 'Size', field: 'size', width: 150 },
        {
            headerName: 'Actions', field: 'actions', cellRenderer: (params) => (
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleVideoPreview(params.data.aws_object_key)}
                    style={{ marginLeft: '10px' }}
                >
                    Preview
                </Button>
            )
        }
    ];

    const transcodingColumnDefs = [
        { headerName: 'Task ID', field: 'task_id', width: 100, sortable: true },
        { headerName: 'User ID', field: 'user_id', width: 100, sortable: true },
        { headerName: 'Video ID', field: 'video_id', width: 100, sortable: true },
        { headerName: 'Progress', field: 'progress', width: 100, sortable: true , cellRenderer: (params) => `${params.value}%`},
        { headerName: 'Input File', field: 'input_file', sortable: true },
        { headerName: 'Output File', field: 'output_file', sortable: true },
        { headerName: 'Status', field: 'status', width: 120, sortable: true },
        { headerName: 'Created On', field: 'create_ts', cellRenderer: (params) => new Date(params.value).toLocaleString(), sortable: true },
        { headerName: 'Updated On', field: 'update_ts', cellRenderer: (params) => new Date(params.value).toLocaleString(), sortable: true },
        {
            headerName: 'Actions',
            field: 'actions',
            cellRenderer: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleProgressButtonClick(params.data.task_id)}
                        style={{ marginRight: 10, marginBottom: 10 }}
                    >
                        View Progress
                    </Button>
                </>
            )
        },
        {
            headerName: 'Actions',
            field: 'actions',
            cellRenderer: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ marginRight: 10, marginBottom: 10 }}
                        onClick={() => handleVideoPreview(params.data.aws_output_object_key)}
                        disabled={params.data.status !== 'completed'}  // Disable when status is not 'completed'
                    >
                        Preview
                    </Button>
                </>
            )
        }
    ];

    const fetchVideoData = async () => {
        try {
            const resp = await axiosInstance.get('/restApi/api/video');
            console.log("🚀 ~ fetchVideoData ~ resp:", resp)
            setVideoData(resp.data.items);
        } catch (error) {
            console.error('Error fetching video data:', error);
            setVideoData([]);
        }
    };

    const fetchTranscodingTasksData = async () => {
        try {
            const resp = await axiosInstance.get('/restApi/api/transcoding_tasks');
            console.log("🚀 ~ fetchTranscodingTasksData ~ resp:", resp)
            setTranscodingTasksData(resp.data.items);
        } catch (error) {
            console.error('Error fetching transcoding tasks data:', error);
            setTranscodingTasksData([]);
        }
    }

    useEffect(() => {
        fetchVideoData();
        fetchTranscodingTasksData();
    }, []);

    const handleRefreshVideos = () => {
        fetchVideoData(); // Refresh the video data
    };

    const handleRefreshTranscodingTasks = () => {
        fetchTranscodingTasksData(); // Refresh the transcoding tasks data
    };

    return (
        <Layout>
            {
                userRole === 'admin' && (
                    <Dashboard uploadedCount={videoData.length} transcodingCount={transcodingTasksData.length} />
                )
            }

            <TranscodeModal
                open={isModalOpen}
                onClose={handleCloseModal}
                videoList={videoData}
                lastTaskId={lastTaskId}
                setLastTaskId={setLastTaskId}
            />
            {/* {showProgress && selectedTaskId && ( */}
            <TranscodingProgress taskId={selectedTaskId} onClose={handleCloseProgress} showProgress={showProgress} />
            {/* )} */}
            <Typography variant="h4" gutterBottom>
                My Videos
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleRefreshVideos} style={{ marginBottom: 10, marginRight: 10 }}>
                Refresh Videos
            </Button>
            <Button variant="contained" color="primary" onClick={handleOpenModal} style={{ marginBottom: 10 }}>
                Select Video for Transcoding
            </Button>
            {/* Dialog for video preview */}
            <Dialog open={open} onClose={handleVideoClose} maxWidth="md" fullWidth>
                <DialogContent>
                    {loading ? (
                        <CircularProgress />
                    ) : previewUrl ? (
                        <video controls width="100%">
                            <source src={previewUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <p>Video not available</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleVideoClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="ag-theme-alpine" style={{ height: 350, width: '100%' }}>
                <AgGridReact
                    rowData={videoData}
                    columnDefs={videoColumnDefs}
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    paginationPageSizeSelector={false}
                />
            </div>
            <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
                My Transcoding Tasks
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleRefreshTranscodingTasks} style={{ marginBottom: 10 }}>
                Refresh Transcoding Tasks
            </Button>
            <div className="ag-theme-alpine" style={{ height: 350, width: '100%' }}>
                <AgGridReact
                    rowData={transcodingTasksData}
                    columnDefs={transcodingColumnDefs}
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    paginationPageSizeSelector={false}
                />
            </div>
        </Layout>
    );
}

export default MyVideosPage;
