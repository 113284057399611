import React from 'react';
import { Box, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

const YoutubeChannelStatsCard = ({ channelStats }) => {
    if (!channelStats) return null;

    return (
        <Box my={4} textAlign="center" p={3} borderRadius="8px" boxShadow={3}>
            <YouTubeIcon color="error" fontSize="large" sx={{ mb: 2 }} />
            <Typography variant="h3" component="h1" gutterBottom>
                {channelStats.snippet.title}
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
                {channelStats.snippet.description}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                    <PersonIcon color="action" />
                    <Typography variant="h6">
                        Subscribers: {Number(channelStats.statistics.subscriberCount).toLocaleString()}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                    <VisibilityIcon color="action" />
                    <Typography variant="h6">
                        Views: {Number(channelStats.statistics.viewCount).toLocaleString()}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                    <VideoLibraryIcon color="action" />
                    <Typography variant="h6">
                        Videos: {Number(channelStats.statistics.videoCount).toLocaleString()}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default YoutubeChannelStatsCard;
