import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Grid, Typography, Card, CardMedia, CardContent } from '@mui/material';
import Layout from '../components/Layout';

const BACKEND_PATH = process.env.REACT_APP_BACKEND_URI + "/pub/";

function GalleryPage() {
    const [videos, setVideos] = useState([]);

    // Fetch published videos
    const fetchPublishedVideos = async () => {
        try {
            const response = await axiosInstance.get('/api/transcoding_tasks/gallery');
            setVideos(response.data.items);
        } catch (error) {
            console.error('Error fetching published videos:', error);
        }
    };

    useEffect(() => {
        fetchPublishedVideos();
    }, []);

    return (
        <Layout>
            <Typography variant="h4" gutterBottom>
                Published Videos
            </Typography>
            <Grid container spacing={2}>
                {videos.map(video => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={video.task_id}>
                        <Card>
                            <CardMedia
                                component="video"
                                controls
                                src={`${BACKEND_PATH}` + video.output_file} // Adjust based on your API response
                                style={{ height: 200 }}
                            />
                            <CardContent>
                                <Typography variant="subtitle1">
                                    Task ID: {video.task_id}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            </Layout>
    );
}

export default GalleryPage;