import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { useGlobalContext } from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { isLogged, userName, logout } = useGlobalContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  const menuId = 'account-menu';

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    logout();
    navigate('/'); // Redirect to home page after logout

  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{}}>
          Video App
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
        <Button color="inherit" component={Link} to="/">Home</Button>
          {isLogged ? (
            <>
              <Button color="inherit" component={Link} to="/my-videos">My Videos</Button>
              <Button color="inherit" component={Link} to="/upload">Upload</Button>
            </>
          ) : null}
        </Box>
        {isLogged ? (
          <span>Hello {userName}</span>
        ) : (
          <Button color="inherit" component={Link} to="/login">Login</Button>
        )}
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </Toolbar>
      {renderMenu}
    </AppBar>
  );
}

export default Header;
