import React from 'react';
import Layout from '../components/Layout';
import VideoUploader from '../components/video/VideoUploader';

function UploadPage() {
  return (
    <Layout>
        <VideoUploader />
    </Layout>
  );
}

export default UploadPage;
