import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [userName, setUserName] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    try {
      const response = await fetchAuthSession();
      console.log("🚀 ~ useEffect ~ response:", response)
      if (response.tokens && response.tokens.accessToken) {
        // Check if user is an admin
        if (response.tokens.accessToken.payload['cognito:groups'] && response.tokens.accessToken.payload['cognito:groups'].includes('adminGroup')) {
          setUserRole('admin');
        } else {
          setUserRole('user');
        }

        setUserName(response.tokens.accessToken.payload.username);
        setIsLogged(true);

        // Check if token has expired
        if (response.tokens.accessToken.payload.exp < Date.now() / 1000) {
          logout();
        }
      }
    } catch (error) {
      console.error("Error checking login status:", error);
    }
  }

  // Method to log out
  const logout = async () => {
    try {
      await signOut();
      // Reset state
      setUserName(null);
      setUserRole(null);
      setIsLogged(false)
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <GlobalContext.Provider value={
      {
        userName,
        setUserName,
        userRole,
        setUserRole,
        isLogged,
        setIsLogged,
        logout,
        checkLogin,
      }
    }>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
