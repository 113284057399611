import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box sx={{ py: 2, mt: 'auto', backgroundColor: 'primary.main', color: 'white', textAlign: 'center' }}>
      <Typography variant="body2">© 2024 Video App</Typography>
    </Box>
  );
}

export default Footer;
