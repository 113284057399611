import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';

function NotFoundPage() {
  return (
    <Layout>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 600, mx: 'auto', mt: 8 }}>
        <Typography variant="h4" gutterBottom>
          404 - Page Not Found
        </Typography>
        <Typography variant="body1" paragraph>
          Sorry, the page you are looking for does not exist.
        </Typography>
        <Box>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Typography variant="body1" color="primary">
              Go back to Home
            </Typography>
          </Link>
        </Box>
      </Paper>
    </Layout>
  );
}

export default NotFoundPage;
