import React from 'react';
import './App.css';
import AppRouter from './navigation/AppRouter';
import { GlobalProvider } from './context/GlobalContext';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
      Cognito: {
          loginWith: {
              oauth: {
                  redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN.split(','), // array
                  redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT.split(','), // array
                  domain: process.env.REACT_APP_COGNITO_DOMAIN,
                  providers: ["Google"],
                  scopes: ['email', 'profile'], // Update to valid scopes
                  responseType: "code",
              },
          },
          userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
          userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
          region: 'ap-southeast-2'
      },
  },
});

function App() {  
  return (
    <GlobalProvider>
      <AppRouter />
    </GlobalProvider>
  );
}

export default App;;
