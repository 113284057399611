// src/axiosInstance.js
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const axiosInstance = axios.create();

async function currentSession() {
  try {
    const response = await fetchAuthSession();
    // console.log("🚀 ~ currentSession ~ response:", response)
      
    const { idToken, accessToken } = response.tokens;

    return {
      idToken: idToken ? idToken.toString() : null,
      accessToken: accessToken ? accessToken.toString() : null,
    }
  } catch (err) {
    console.log("currentSession in axiosInstance:", err);
    // prevent error when the user is not authenticated
    return {
      idToken: null,
      accessToken: null,
    };
  }
}

// Add a request interceptor
axiosInstance.interceptors.request.use(async (config) => {
  // detect local testing evnironment
  if (process.env.REACT_APP_LOCAL_TESTING === 'true') {
    if (config.url.startsWith('/youtubeApi')) {
      config.baseURL = process.env.REACT_APP_YOUTUBE_API_URI;
    } else if (config.url.startsWith('/restApi')) {
      config.baseURL = process.env.REACT_APP_REST_API_URI;
    } else if (config.url.startsWith('/videoManagementApi')) {
      config.baseURL = process.env.REACT_APP_VIDEO_MANAGEMENT_API_URI;
    } else if (config.url.startsWith('/transcodeApi')) {
      config.baseURL = process.env.REACT_APP_TRANSCODE_API_URI;
    } 
  } else {
    config.baseURL = process.env.REACT_APP_BACKEND_URI;
  }


  // get the current session
  const response = await currentSession();
  const { idToken, accessToken } = response;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  // NOTE: it is not necessary to have idToken in the header
  // if (idToken) {
  //   config.headers['x-id-token'] = `Bearer ${idToken}`;
  // }

  return config;
}, (error) => Promise.reject(error));

export default axiosInstance;
