import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper } from '@mui/material';
import Layout from '../components/Layout';
import YouTubeChannel from '../components/YoutubeChannel';

function Home() {
  return (
    <Layout>
    <Paper elevation={3} sx={{ p: 4, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Welcome to Video App
      </Typography>
      <Box>
        <Typography variant="body1" paragraph>
          This is a simple video management application. You can log in, view your uploaded videos, upload new videos, and transcode existing ones.
        </Typography>
        <Typography variant="body1" paragraph>
          Use the navigation bar to access different sections of the app.
        </Typography>
      </Box>
        <YouTubeChannel />
    </Paper>
    </Layout>
  );
}
export default Home;
