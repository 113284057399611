// Dashboard.js
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const Dashboard = ({ uploadedCount, transcodingCount }) => {
    return (
        <>
        <Typography variant="h4" gutterBottom>
           Dashboard
        </Typography>
        <Grid container spacing={3} style={{ marginBottom: 20 }}>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Uploaded Videos
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                            {uploadedCount}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Transcoding Videos
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                            {transcodingCount}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </>
    );
};

export default Dashboard;
